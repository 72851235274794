jQuery = $ = require('jquery');

require('smartmenus');
require('slick-carousel');

$(window).load(function(){
    //preloader
  $(".preloader__img").fadeOut();
  $(".preloader").delay(200).fadeOut("slow").delay(200, function(){
    $(this).remove();
  });
});

$(function() {
  var
      $body          = (window.opera) ? (document.compatMode == "CSS1Compat" ? $('html') : $('body')) : $('html,body'),
      $banner        = $('.js-banner'),
      $menu          = $('.navi'),
      $aside         = $('aside ul'),
      $map_1         = $('#map_1'),
      $map_2         = $('#map_2'),
      $iframe        = $('iframe'),
      $frame         = $('.frame'),
      $backToTop     = $('.backToTop'),
      $img           = $('img'),
      $this          = $(this),
      $windowScroll  = 0,
      $scrollUpStart = 0;

  // smartmenus
  if ($menu.length) {
    $menu.smartmenus({
      subMenusSubOffsetX: 0,
      subMenusSubOffsetY: 0,
      markCurrentItem: true,
      arrowClicked: true,
      subMenusMinWidth: '16em',
      subMenusMaxWidth: '16em'
    });
  }

  if ($aside.length) {
    $aside.smartmenus({
      markCurrentItem: true,
      arrowClicked: true
    });
  }

  // back to top button
  $backToTop.hide();
  $(window).scroll(function() {
    $windowScroll = $(window).scrollTop();

    if($windowScroll < 50) {
      if ($backToTop.length) {
        $backToTop.hide();
      }
    } else {
      if ($backToTop.length) {
        $backToTop.show();
      }
    }
  });

  $backToTop.click(function(e) {
    $('body').animate({ scrollTop: 0 }, 600);
    $backToTop.blur();
  }).scroll();


  /* -------------------
      Scroll Event
  ------------------- */
  $backToTop.hide();

  $(window).scroll(function() {
    $windowScroll = $(window).scrollTop();

    if($windowScroll <= 100) {
      if ($backToTop.length) { $backToTop.hide(); }
    } else {
      if ($backToTop.length) { $backToTop.show(); }
    }
  });

  $backToTop.click(function(e){
    $body.animate({ scrollTop: 0 }, 600);
    $backToTop.blur();
  }).scroll();


  /* -------------------
      Can't copy and contextmenu
  ------------------- */
  $(document).bind("contextmenu",function(e){
    alert('All rights reserved.');
    return false;
  });

  //courtesy of BoogieJack.com
  function killCopy(e){
    return false;
  }

  function reEnable(){
    return true;
  }

  document.onselectstart = new Function ("return false");

  if (window.sidebar){
    document.onmousedown = killCopy
    document.onclick = reEnable
  }
});